import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { slugs } from '../../../Constant/slugs';

export default function Dashboard() {
  const data = [
    {
      slug: slugs.exam,
      image: "https://api.anskubt.com/wp-content/uploads/2023/06/AllExam.jpeg"
    },
    {
      slug: slugs.demo_exam,
      image: "https://api.anskubt.com/wp-content/uploads/2023/06/DemoExam.jpeg"
    },
    {
      slug: slugs.chapter_wise_exam,
      image: "https://api.anskubt.com/wp-content/uploads/2023/06/ChapterExam.jpeg"
    },
    {
      slug: slugs.exam_result_list,
      image: "https://api.anskubt.com/wp-content/uploads/2023/06/ExamResults.jpeg"
    },
    {
      slug: slugs.chapter_wise_exam_result_list,
      image: "https://api.anskubt.com/wp-content/uploads/2023/06/ChapterResults.jpeg"
    },
    {
      slug: slugs.books,
      image: "https://api.anskubt.com/wp-content/uploads/2023/06/Books.jpeg"
    },
  ]
  const Block = ({ slug, image }) => {
    return (
      <div className="col-lg-4 col-md-6" >
        <Link style={{
          textDecorationLine: 'none',
          display: 'flex',
          justifyContent: 'center',
          // color: "#000",
        }}
          to={`${slug}`}
        >

          <div style={{
            backgroundColor: "#ffffff",
            display: 'flex',
            alignContent: 'center', alignItems: 'center',
            padding: "5px", margin: "10px 0px", border: "1px solid black",
            width: "220px", height: "220px",
            borderRadius: "5px"
          }}
            className="home-box center"
          >
            <img
              style={{
                backgroundColor: "#fff", border: "1px solid black",
                width: "220px", height: "220px",
                borderRadius: "5px"
              }}
              src={image}
            />
          </div>
        </Link>

      </div>
    )
  }
  return (
    <div>
      <div>
        <div className="row">
          <div className="col-1"></div>
          <div className="col-10 form-view mt-3">
            <div className="row" style={{ margin: "10px" }}>
              {data.map((item, index) => (
                <Block slug={item.slug} image={item.image} />
              ))}
            </div>
          </div>
        </div>
      </div >
    </div >
  )
}